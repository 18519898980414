import * as React from 'react'
import { ZipCodePanel } from '../../components'

function ZipCodePage() {
  /**
   * NOTE: This is the only `[form]` page that puts all page logic inside its
   * respective `FormPanel` component. The reason is because the `ZipCodePanel` is
   * used on both the `/[form]` route as well as the `/savings/generic` route, and
   * the UI/UX is nearly identical on both routes.
   *
   * For all other `[form]` pages, most of the logic should live in the respective
   * page, rather than within the `FormPanel`. Although some of the logic has changed
   * slightly in this new repo, you should still reference the old repo for guidance
   * on where to put the various functions and UI components on the other pages.
   */
  return <ZipCodePanel />
}

export async function getStaticPaths() {
  return {
    paths: [
      { params: { form: 'get-quote' } },
      { params: { form: 'medicare-quote' } },
      { params: { form: 'health-insurance-quote' } },
    ],
    fallback: false,
  }
}

export async function getStaticProps() {
  return {
    props: {},
  }
}

export default ZipCodePage
